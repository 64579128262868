:root {
    --textHiddenColor: #642ab5;
    --textWhiteColor: #fff;
    --backgroundWhiteColor: #ddd;
    --textBlackColor: #000;
    --backgroundColor: #FEFEFE;
    --backgroundTransParentColor: rgba(0, 0, 0, 0.6);
    --themeDarkestColor: #1a1325;
    --themeExtraDarkColor: #24163a;
    --themeDarkColor: #301c4d;
    --themeNormalColor: #3e2069;
    --themeLightColor: #51258f;
    --textLightColor: rgb(134, 134, 134);
    --purple7: #854eca;
    --purple8: #ab7ae0;
    --purple9: #cda8f0;
    --lightPurple: #ebd7fa;
    --accentColor: #177ddc;
    --primaryColor: #998eb5;
    --textPrimaryColor: #1f1f1f;
    --lightGrayColor: #e6e6e6;
    --grayAccent: #aaa;
    --grayColor: #595959;
    --darkGrayColor: #141414;
    --backgroundGradient: linear-gradient(to left, #998eb5, #998eb5);
    /* --buttonColor: radial-gradient(73.62% 92.72% at 50.59% 46.59%, #7C4296 0%, #D53876 100%); */
    --buttonColor: #998eb5;
    --buttonLightColor: radial-gradient(73.62% 92.72% at 50.59% 46.59%, #998eb5, #998eb5 100%); 
    --buttonSecondaryColor: #998eb5; 
    --buttonSecondaryTextColor: #fff; 
    --subscribeColor: #ccc;
    --foregroundColor: #ddd;
    --backgroundLoaderColor: #eee;
}   

.dark-theme {
    --backgroundColor: #201d29;
    --backgroundTransParentColor: rgba(150, 150, 150, 0.6);
    --textPrimaryColor: #fff;
    --textWhiteColor: #120338;
    --themeNormalColor: #eee;
    --lightGrayColor: #2b2b50;
    --foregroundColor: #1e1e36;
    --backgroundLoaderColor: #20203b;
    --backgroundWhiteColor: #312c3e;
    --buttonColor: #998eb5;
    --grayColor: #ccc;
    --textLightColor: #ccc;
    --grayAccent: #606060;
    --textBlackColor: #fff;
    --primaryColor: #177ddc;
    --subscribeColor: #20203b;
}