.sale-list {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    margin-top: 8px;
    justify-content: center;
}

.load-more {
    display: flex;
    justify-content: center;
}

.load-more button {
    margin-top: 20px;
    color: var(--textPrimaryColor);
    border-radius: 8rem;
    padding: 0 1rem;
    height: 40px;
    font-size: 1rem;
    outline: none;
    border: 1px solid var(--grayAccent);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    background: var(--backgroundWhiteColor);
    font-weight: 700;
}

@media only screen and (max-width: 450px) {
    .sale-list {
        display: block;
    }
}