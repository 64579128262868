.my-header {
  position: relative;
  height: 500px;
}

.my-header .my-header-cover {
  height: 300px;
  overflow: hidden;
}

.edit-cover-container {
  position: absolute;
  z-index: 100;
  top: 250px;
  right: 12px;
}

.edit-cover-container .edit-cover-btn {
  display: inline-block;
  font-weight: 700;
  color: var(--textPrimaryColor);
  background: var(--backgroundWhiteColor);
  height: 40px;
  border-radius: 8rem;
  padding: 0 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
}

#my-file-cover {
  display: none;
}

.edit-cover-container svg {
  display: none;
  font-size: 2.2rem;
  border-radius: 8rem;
  padding: 10px;
  background: var(--textWhiteColor);
  outline: none;
}

.my-header .my-header-cover img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.my-header .my-header-user-context {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 46%;
  width: 70%;
  left: 20%;
}

.my-header-user-context img {
  width: 220px;
  height: 220px;
  object-fit: cover;
  border-radius: 8rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  border: 5px solid white;
  background: white;
}

.my-header-user-context .user-content-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  margin-top: 4rem;
}

.my-header-user-context .user-content {
  text-align: left;
  margin-left: 5%;
}

.user-content h1 {
  margin: 0;
  font-weight: 700;
  color: var(--textPrimaryColor);
}
.user-content h2 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--textPrimaryColor);
}
.user-content h2 > span {
  cursor: pointer;
  color: var(--textLightColor);
}
.user-content h4 {
  margin: 0;
  font-weight: 500;
  color: var(--textLightColor);
  width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.user-content p {
  margin: 0;
  font-size: 1rem;
  color: var(--accentColor);
  font-weight: 700;
  cursor: pointer;
}

.my-header-user-context .user-setting {
  padding: 8px;
  display: flex;
  align-items: center;
}

.user-content-box .user-setting-container button {
  color: var(--textPrimaryColor);
  border-radius: 8rem;
  padding: 0 1rem;
  height: 40px;
  font-size: 1rem;
  outline: none;
  border: 1px solid var(--grayAccent);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background: var(--backgroundWhiteColor);
  font-weight: 700;
}

.user-content-box .user-setting button:active {
  transform: scale(0.97);
  transition: 0.1s ease-in-out;
}

.user-content-box .user-setting svg {
  font-size: 1.5rem;
  border-radius: 8rem;
  border: 1px solid var(--grayAccent);
  color: var(--textBlackColor);
  width: 40px;
  height: 40px;
  padding: 12px;
  cursor: pointer;
  margin-left: 8px;
  transition: 0.3s ease-in-out;
}

.user-content-box .user-setting svg:active {
  transform: scale(0.95);
  transition: 0.1s ease-in-out;
}

.user-address {
  display: flex;
  align-items: center;
}

.user-address svg {
  margin-left: 1rem;
  color: var(--textLightColor);
}

.user-address svg:hover {
  cursor: pointer;
  color: var(--textPrimaryColor);
}

/* global read more styling */
.read-more-button {
  color: var(--accentColor);
  font-weight: 500;
  cursor: pointer;
}

.displayed-text {
  margin: 0;
  font-weight: 500;
  color: var(--textLightColor);
  width: 80%;
}

.social-share-body {
  padding: 8px 1rem;
}

.social-share-body .social-share-title {
  color: var(--textPrimaryColor);
  font-weight: 700;
}

.social-share-body .social-share-tags {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.social-share-tags svg {
  /* border: 1px solid var(--grayAccent);
    border-radius: 8rem; */
  font-size: 2rem;
  padding: 8px;
}

@media only screen and (max-width: 450px) {
  .my-header {
    height: 600px;
  }

  .my-header .my-header-user-context {
    flex-flow: column;
    align-items: center;
    top: 20%;
    width: 100%;
    left: 0;
    margin: 0 auto;
  }

  .my-header-user-context .user-content-box {
    flex-flow: column;
    align-items: center;
    margin-top: 0;
  }

  .user-content-box .user-content {
    text-align: center;
    margin: 0;
    width: 95%;
  }

  .user-content-box .user-content h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 80%;
  }

  .user-address {
    width: 70%;
    margin: 0 auto;
  }

  .user-content h4 {
    width: 100%;
  }

  .my-header .my-header-cover img {
    height: 220px;
  }

  .edit-cover-container {
    position: absolute;
    z-index: 500;
    top: 170px;
    right: 12px;
  }
  .edit-cover-container .edit-cover-btn {
    display: none;
  }

  .edit-cover-container svg {
    display: inline-block;
  }
}
