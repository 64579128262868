@import url("../../utils/Colors.css");

.seller-item {
  margin: 1rem;
  border: 1px solid transparent;
  padding: 12px;
  transition: 0.3s ease-in-out;
  background: var(--backgroundWhiteColor);
  border-radius: 1rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.seller-item .seller-item-container {
  display: flex;
  align-items: center;
}

.seller-item:hover {
  border: 1px solid var(--lightGrayColor);
  padding: 12px;
  transition: 0.3s ease-in-out;
  border-radius: 1rem;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.seller-item .seller-item-number {
  color: var(--textLightColor);
  margin: 0;
  font-size: 1rem;
  font-weight: 800;
}

.seller-item-image {
  position: relative;
  margin: 0;
}

.seller-item .seller-avatar {
  width: 50px;
  height: 50px;
  border-radius: 8rem;
  object-fit: cover;
}

.seller-item .seller-badge {
  width: 25px;
  height: 25px;
  object-fit: contain;
  position: absolute;
  bottom: -10%;
  right: -15%;
}

.seller-item-content {
  text-align: left;
  width: 100%;
  margin: 0 1rem;
}

.seller-item .seller-item-content h3 {
  margin: 0;
  color: var(--textPrimaryColor);
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
}
.seller-item .seller-item-content p {
  margin: 0;
  color: var(--textLightColor);
  font-weight: 700;
  border-radius: 4px;
  width: 100%;
  white-space: nowrap;
}
