@import url("./utils/Colors.css");

.App {
  text-align: center;
  background-color: var(--backgroundColor);
  transition: 0.3s ease-in-out;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
scrollbar custom */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grayAccent);
  border-radius: 8rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--textLightColor);
}
.ant-avatar-group .ant-avatar {
  border: none !important;
}

/* 
global css for carousel  */
.next-arrow {
  position: absolute;
  right: -1%;
  top: 45%;
  padding: 12px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--buttonColor);
  height: 50px;
  border-radius: 8rem;
  z-index: 100;
  transition: 0.3s ease-in-out;
}

.next-arrow:hover {
  cursor: pointer;
  box-shadow: 0 8px 8px rgba(54, 4, 83, 0.5);
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.prev-arrow {
  position: absolute;
  left: -1%;
  top: 45%;
  padding: 12px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--buttonColor);
  height: 50px;
  border-radius: 8rem;
  z-index: 100;
  transition: 0.3s ease-in-out;
}

.prev-arrow:hover {
  cursor: pointer;
  box-shadow: 0 8px 8px rgba(54, 4, 83, 0.5);
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.ant-dropdown ul {
  background: var(--backgroundWhiteColor);
  border-radius: 8px;
  border: 1px solid var(--lightGrayColor);
  padding: 12px;
}

.ant-empty-description {
  color: var(--textPrimaryColor);
}

.ant-dropdown ul li {
  font-size: 1rem;
  color: var(--textPrimaryColor);
  padding: 8px;
  font-weight: 500;
  border-radius: 8px;
}
.ant-dropdown ul li > a {
  color: var(--textPrimaryColor);
}
.ant-dropdown ul li > a:hover {
  color: var(--textPrimaryColor);
}

.ant-dropdown ul li:hover {
  background: var(--lightGrayColor);
}

.ant-dropdown ul li > span {  
  font-size: 1rem;
  font-weight: 500;
}

.ant-dropdown ul h3 {
  color: var(--textLightColor);
  padding: 0 10px;
}

.ant-tabs-tab-btn {
  font-weight: 700;
  color: var(--textPrimaryColor);
  font-size: 1rem;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primaryColor) !important;
  font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {
  color: var(--primaryColor) !important;
  font-weight: 700;
}

.ant-tabs-ink-bar {
  background: var(--primaryColor) !important;
}

.ant-drawer-header .ant-drawer-title {
  font-size: 1.3rem;
  font-weight: 700;
}

.ant-drawer-content {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  /* border-radius: 1rem; */
  background: var(--backgroundWhiteColor) !important;
}

.ant-drawer-header {
  background: var(--backgroundWhiteColor) !important;
}

.ant-drawer-header .ant-drawer-title {
  color: var(--textPrimaryColor);
}

.ant-drawer-header svg {
  color: var(--textPrimaryColor);
}

.ant-drawer-body {
  padding-top: 0 !important;
}

.ant-modal-content {
  background: var(--backgroundWhiteColor) !important;
}

.ant-modal-header {
  background: var(--backgroundWhiteColor) !important;
  border-bottom: 1px solid var(--lightGrayColor) !important;
}

.ant-modal-title {
  color: var(--textBlackColor) !important;
}

.ant-modal-close-x svg {
  color: var(--textBlackColor) !important;
}

.ant-modal-body {
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .next-arrow {
    right: 0%;
    width: 30px;
    height: 30px;
    display: none;
  }
  .prev-arrow {
    left: 0%;
    width: 30px;
    height: 30px;
    display: none;
  }
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
