.search-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 8px;
}

.search-item img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
}

.search-item-info {
    margin-left: 1rem;
}

.search-item h4 {
    margin: 0;
    color: var(--textPrimaryColor);
    font-weight: 700;
}

.search-item p {
    margin: 0;
    color: var(--textLightColor);
}
.search-item p > span{
    margin: 0;
    color: var(--primaryColor);
}

.search-item:hover {
    background: var(--lightGrayColor);
    cursor: pointer;
}