.search-page {
    padding-top: 100px;
    text-align: left;
    width: 90%;
    margin: 1rem auto;
}

.search-page h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--textLightColor);
}

.search-page h1 > span {
    color: var(--textPrimaryColor);
}

@media only screen and (max-width: 450px) {
    .search-page h1 {
        font-size: 1.5rem;
    }

    .search-page {
        padding-top: 90px;
    }
}