.create-container {
    padding-top: 120px;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
}

@media only screen and (max-width: 450px) {
    .create-container {
        width: 95%;
        padding-bottom: 0;
        padding-top: 100px;
    }
}