.activity-my-filter {
  width: 30%;
  padding: 2rem;
  text-align: left;
  position: sticky;
  top: 80px;
}
.my-activity-filter {
  width: 100%;
  text-align: left;
  position: sticky;
  top: 80px;
}

.activity-my-filter h1 {
  color: var(--textBlackColor);
}
.my-activity-filter h1 {
  color: var(--textBlackColor);
  font-size: 1rem;
}

.activity-my-filter-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -8px;
  position: sticky;
  position: -webkit-sticky;
  top: 5%;
}

my-activity-filter-buttons {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.activity-my-filter img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.my-activity-filter img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.activity-my-filter-buttons button {
  height: 40px;
  padding: 0 1rem;
  margin: 8px;
  border-radius: 8rem;
  border: none;
  outline: none;
  background: var(--lightGrayColor);
  color: var(--textBlackColor);
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}
.my-activity-filter-buttons button {
  height: 40px;
  padding: 0 1rem;
  margin: 8px;
  border-radius: 8rem;
  border: none;
  outline: none;
  background: var(--lightGrayColor);
  color: var(--textBlackColor);
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.listing-btn-back {
  background: #cf1322 !important;
  color: #fff1f0 !important;
}
.purchase-btn-back {
  background: #08979c !important;
  color: #e6fffb !important;
}
.sale-btn-back {
  background: #c41d7f !important;
  color: #fff0f6 !important;
}
.transfer-btn-back {
  background: #389e0d !important;
  color: #f6ffed !important;
}
.burn-btn-back {
  background: #d4380d !important;
  color: #fff2e8 !important;
}
.bid-btn-back {
  background: #d46b08 !important;
  color: #fff7e6 !important;
}
.like-btn-back {
  background: #c41d7f !important;
  color: #fff0f6 !important;
}
.follow-btn-back {
  background: #096dd9 !important;
  color: #e6f7ff !important;
}

@media only screen and (max-width: 450px) {
  .activity-my-filter {
    display: none;
  }
  .drawer-filter {
    text-align: left;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
  }

  .drawer-filter-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -8px;
  }

  .drawer-filter img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .drawer-filter-buttons button {
    height: 40px;
    padding: 0 1rem;
    margin: 8px;
    border-radius: 8rem;
    border: none;
    outline: none;
    background: var(--lightGrayColor);
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
  }

  .drawer-filter .show-filter-button {
    height: 40px;
    padding: 0 1rem;
    border-radius: 8rem;
    border: none;
    outline: none;
    background: var(--buttonColor);
    color: white;
    font-weight: 700;
    cursor: pointer;
  }

  .ant-drawer-content {
    border-radius: 0 !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
