.activity-container {
  padding-top: 80px;
  text-align: left;
  width: 70%;
  margin: 0 auto;
}

.activity-container .activity-headline {
  display: flex;
  flex-flow: column;
}

.activity-container-headline {
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--textPrimaryColor);
}

.activity-container .filter-toggle-button {
  display: none;
}

.activity-container-context {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.activity-container .ant-tabs-top {
  min-width: 100%;
}

@media only screen and (max-width: 450px) {
  .activity-container {
    padding: 12px;
    padding-top: 80px;
    width: 90%;
  }

  .activity-container .activity-headline {
    flex-flow: row;
  }

  .activity-container-headline {
    text-align: left;
    padding: 0;
    font-size: 1.5rem;
    margin: 0;
  }

  .ant-tabs {
    width: 100%;
  }

  .activity-container .activity-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .activity-headline .filter-toggle-button {
    display: block;
  }

  .activity-headline button {
    background: var(--buttonSecondaryColor);
    border: none;
    color: var(--buttonSecondaryTextColor);
    height: 30px;
    padding: 0 2rem;
    border-radius: 8rem;
    outline: none;
  }

  .activity-main-filter {
    display: none;
  }
}
