@import url("../../utils/Colors.css");

.collection-item {
  border: 1px solid var(--lightGrayColor);
  border-radius: 1rem;
  margin: 12px;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12);
  background: white;
}

.collection-item .collection-cover {
  position: relative;
}

.collection-cover .collection-cover-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  cursor: pointer;
}

.collection-seller-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.collection-cover .collection-seller-context {
  position: relative;
}

.collection-cover .collection-seller-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 8rem;
  border: 2px solid var(--textWhiteColor);
  cursor: pointer;
}

.collection-cover .collection-badge {
  width: 25px;
  height: 25px;
  object-fit: contain;
  position: absolute;
  right: -2%;
  bottom: -2%;
}

.collection-content {
}

.collection-content h2 {
  color: var(--textWhiteColor);
  font-size: 1.3rem;
  margin: 0;
  padding: 0 1rem;
  padding-top: 8px;
  cursor: pointer;
}
.collection-content h3 {
  color: var(--grayAccent);
  font-size: 1.0rem;
  margin: 0;
  padding: 0 1rem;
}

.collection-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--backgroundTransParentColor);
}
