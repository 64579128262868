.create-single-container {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.create-context .special-text {
  background: -webkit-linear-gradient(#9813e5, #5f21e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.create-single-upload {
  width: 60%;
  padding: 0 1rem;
}

.create-single-upload h1 {
  font-weight: 700;
  color: var(--textPrimaryColor);
}

.create-single-upload p {
  font-weight: 700;
  color: var(--textPrimaryColor);
}

.create-single-upload .upload-box {
  border: 2px dashed #ddd;
  border-radius: 1rem;
  padding: 2rem 4rem;
  position: relative;
  width: 90%;
}

.upload-box .upload-input {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: var(--grayColor);
}

.upload-box-container {
  margin-top: 25px;
}

.upload-box .upload-input p {
  font-weight: 500;
  color: var(--grayAccent);
}

#my-create-input {
  display: none;
}

/* .upload-box .upload-box-input {
  background-color: var(--buttonColor) !important;
  border: none;
  border-radius: 8rem;
  color: white;
  cursor: pointer;
  width: 100%;
  display: none;
} */

.upload-box button {
  background-color: var(--buttonColor) !important;
  border: none;
  border-radius: 8rem;
  height: 40px;
  color: white;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
}

.upload-box input::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-box img {
  max-width: 100%;
  height: 150px;  
  object-fit: fill;  
}

.upload-box audio {
  max-width: 100%;  
  object-fit: fill; 
}

.upload-box video {
  max-width: 100%;
  height: 150px;  
  object-fit: fill; 
}

.upload-box svg {
  position: absolute;
  right: 5%;
  top: 5%;
  font-size: 1.8rem;
  color: var(--grayAccent);
}

.upload-box svg:hover {
  color: black;
  cursor: pointer;
}

.create-single-upload .create-context {
  max-width: 90%;
}

.switch-type {
  margin-top: 1.5rem;
}
.create-single-upload .regular-item {
  margin-top: 1.5rem;
}

.create-context h3 {
  margin: 0px;
  font-weight: 700;
  color: var(--textPrimaryColor);
  font-size: 0.9rem;
}

.create-context span {
  color: var(--textPrimaryColor);
  font-weight: 700;
}

.create-context h2 {
  margin: 0;
  font-weight: 700;
  color: var(--textPrimaryColor);
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.create-context p {
  margin: 0;
  color: var(--textLightColor);
  font-weight: 500;
  font-size: 0.9rem;
}

.create-context input {
  margin-bottom: 8px;
  border-radius: 8rem;
  padding: 8px 1rem;
  background: var(--backgroundWhiteColor);
  color: var(--textPrimaryColor);
  border: 1px solid var(--lightGrayColor);
}
.regular-select-currency {
  outline: none;
  border-top-right-radius: 8rem;
  border-bottom-right-radius: 8rem;
  background-color: var(--backgroundWhiteColor);
  border: 1px solid var(--lightGrayColor);
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-switch-checked {
  background-color: var(--purple7);
}

.regular-item .regular-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  flex-wrap: wrap;
}

.ant-input {
  background: var(--backgroundWhiteColor) !important;
}

.regular-item .regular-collection-card {
  padding: 1.5rem 1rem;
  border: 1px solid var(--grayAccent);
  border-radius: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  height: 140px;
  width: 140px;
}

.regular-collection-card:hover {
  border: 1px solid var(--grayAccent);
}

.regular-item .active {
  border: 1px solid var(--textPrimaryColor);
}

.regular-collection-card svg {
  font-size: 32px;
  border-radius: 8rem;
  border: 1px solid var(--grayAccent);
  padding: 6px;
}
.regular-collection-card img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.auction-time-container {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;  
}

.auction-time-field {
  width: 48%;
  padding: 8px 12px 0 12px;
}
.auction-time-field .input-picker {
  width: 100%;
  margin-top: 12px;
  border-radius: 8px;
  padding: 8px;
  border-width: 1px;
  font-size: 14px;
}

.auction-time-select {
  width: 230px;
  border: unset;
  border-bottom: 1px solid rgb(234, 236, 239);
  font-size: 14px;
  padding: 8px;
  width: 100%;  
}
.auction-time-select:focus-visible {
  outline: unset;
}

@media only screen and (max-width: 1300px) {
  .create-single-upload {
    width: 80%;
    padding: 0;
  }  
  .auction-time-container {
    display: block;
  }
  .auction-time-field {
    width: 100%;
    padding: 8px 12px 0 12px;
  }
}


@media only screen and (max-width: 450px) {
  .create-single-upload {
    width: 100%;
    padding: 0;
  }
  .create-single-upload .upload-box {
    width: 100%;
  }
  .create-single-upload .create-context {
    max-width: 100%;
  }
  .auction-time-container {
    display: block;
  }
  .auction-time-field {
    width: 100%;
    padding: 8px 12px 0 12px;
  }
}
