.my-content-activity-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.activity-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.activity-item-list {
    width: 90%;
}


@media only screen and (max-width: 450px) {
    .activity-item-list {
        width: 100%;
    }
}